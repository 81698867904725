<template>
    <div class="hero">
        <div class="contentZone">
            <div class="heroBox">
                <h1 class="title1b">{{ titolo }}</h1>
                <div class="subtitle" v-if="descrizione">
                    <p>{{ descrizione }}</p>
                </div>
                <div>
                    <NuxtLinkLocale :to="link.address" :custom="true">
                        <template v-slot="{ navigate }">
                            <NButton size="medium" type="primary" round icon-placement="right" @click="navigate" tabindex="-1" :aria-label="`${$t('scopri_di_piu')} ${titolo || ''}`">
                                {{ $t('scopri_di_piu') }}
                                <template #icon>
                                    <NIcon :component="ArrowForwardRound" :aria-label="$t('freccia')" />
                                </template>
                            </NButton>
                        </template>
                    </NuxtLinkLocale>
                </div>
            </div>
        </div>
        <div class="imgCont">
            <Image v-if="cover" :image="cover" :width="width" :height="height" imageClass="cover" :key="cover.id" :lazyload="false" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { NIcon, NButton } from 'naive-ui';
import { ArrowForwardRound } from '@vicons/material'

const { getCardCover, getCardTitolo, getCardDescrizione, getCardLink } = useCard()

interface Props {
    card: any,
    typename?: string | null,
    width?: number,
    height?: number,
}

const props = withDefaults(defineProps<Props>(), {
    width: 1600,
    height: 630
})

const cover = computed(() => getCardCover(props.card))
const titolo = computed(() => getCardTitolo(props.card))
const descrizione = computed(() => getCardDescrizione(props.card))
const link = computed(() => getCardLink(props.card, props.typename))
</script>

<style lang="scss" scoped>
.hero {
    position: relative;

    background-color: var(--color-background-image);
    height: 100%;
    min-height: var(--size-hero-minheight);
    max-width: var(--size-hero-max);
    border-radius: var(--size-hero-radius);

    margin: 0 auto;
    overflow: hidden;

    .contentZone {
        position: relative;
        z-index: 1;
        padding: calc(var(--margin-header) * 2 - 10px);
        padding-top: var(--size-hero-safetop);

        pointer-events: auto;
        transform: translate3d(0, 0, 0);
    }

    .imgCont {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 0;

        transform: translate3d(0, 0, 0);
    }
}

@media screen and (max-width: 1360px) {
    .hero {

        .contentZone {
            padding: var(--size-hero-safetop) 20px 20px 20px;
            max-width: 1200px;
            margin: 0 auto;
        }
    }
}

@media screen and (max-width: 600px) {

    .hero {
        border-radius: 0;
        min-height: 0;

        .contentZone {
            padding: 50vh var(--margin-page) var(--margin-page) var(--margin-page);
        }
    }
}
</style>