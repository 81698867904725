<template>
    <div class="videoCont" :style="{ backgroundImage: holdBackground }">
        <video :class="[{lazy: loaded === false}]" muted loop autoplay playsinline @canplay="loaded = true" @canplaythrough="loaded = true" @play="loaded = true" @load="loaded = true" ref="video">
            <source :src="`${directusUrl}/assets/${videoMp4.id}`" type="video/mp4">
            <source :src="`${directusUrl}/assets/${videoWebm.id}`" type="video/webm">
        </video>
    </div>
</template>

<script lang="ts" setup>
import { blurhashToCssGradientString } from "@unpic/placeholder";

interface Props {
    videoMp4: any;
    videoWebm: any;
    cover: {
        id: string;
        height?: number;
        width?: number;
        title?: string;
        blurhash?: string;
    };
}
const props = defineProps<Props>()
const directusUrl = useDirectusUrl()

const video = ref<HTMLVideoElement>()
const loaded = ref(false)

const holdBackground = computed(() => {
    if (props.cover && props.cover.blurhash) return blurhashToCssGradientString(props.cover.blurhash)
    else return 'transparent'
})

onMounted(() => {
    if (video.value && video.value.readyState > 0) loaded.value = true
})
</script>

<style lang="scss">
.videoCont {
    display: block;
    height: 100%;
    width: 100%;

    video {
        transition: opacity 300ms 0ms ease-in-out;

        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;

        &.lazy {
            opacity: 0;
        }
    }
}
</style>